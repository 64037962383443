<template>
  <div>
    <h1>
      <span @click="$router.push('/')" class="h1__title">Главная</span>
      <i>&rarr;</i>
      <span class="h1__current">Обработка товара</span>
    </h1>
    <div class="inside">
      <h2 class="mb-4 text-2xl">Заполните данные</h2>
      <form @submit.prevent="preSubmit($event, redirectToDetail)" class="form" autocomplete="off" enctype="multipart/form-data">

        <!-- ТОВАРЫ -->
        <div class="form-block">
          <div class="form-block__info">
            Товары<br>
            <span class="form-block__desc">
              <b>ВНИМАНИЕ!</b> Данные, о товаре, которые вы заполняете,
              попадут на этикетку вашего товара БЕЗ РЕДАКТИРОВАНИЯ!
              Будьте внимательны при заполнении!<br><br>

            </span>
          </div>
          <div class="form-block__fields">
            <div>
              <button v-if="!productList.length" @click="productAdd = true" type="button" class="order-add-btn base-button m-0">
                Добавить товар
              </button>
              <div v-else class="product-list">
                <div v-for="(item, key) in productList" :key="key" class="product-list__item">
                  <div class="hidden">
                    <input :ref="'file' + key" :name="'prod-photo[' + key + ']'" type="file" class="hidden">
                    <input :value="item['prod-name']" :name="'prod-name[' + key + ']'" type="hidden">
                    <input :value="item['prod-art']" :name="'prod-art[' + key + ']'" type="hidden">
                    <input :value="item['prod-barcode']" :name="'prod-barcode[' + key + ']'" type="hidden">
                    <input :value="item['prod-brand']" :name="'prod-brand[' + key + ']'" type="hidden">
                    <input :value="item['prod-country']" :name="'prod-country[' + key + ']'" type="hidden">
                    <input :value="item['prod-color']" :name="'prod-color[' + key + ']'" type="hidden">
                    <input :value="item['prod-size']" :name="'prod-size[' + key + ']'" type="hidden">
                    <input :value="item['prod-sostav']" :name="'prod-sostav[' + key + ']'" type="hidden">
                    <input :value="item['prod-amount']" :name="'prod-amount[' + key + ']'" type="hidden">
                    <input :value="item['stock']" :name="'stock[' + key + ']'" type="hidden">
                    <input :value="item['prod-ip']" :name="'prod-ip[' + key + ']'" type="hidden">
                    <input :value="item['prod-other']" :name="'prod-other[' + key + ']'" type="hidden">
                  </div>
                  <div class="product-list__photo" :style="getProductPic(key)"></div>
                  <div class="product-list__props">
                    <div class="product-list__name">{{ getProductTitle(key) }}</div>
                    <a @click="productEdit = key" href="#">Редактировать</a>&nbsp;&nbsp;
                    <a @click="productDeleteMethod(key)" href="#">Удалить</a>&nbsp;&nbsp;
                    <a @click="duplicateProduct(key)" href="#">Копировать</a>
                  </div>
                </div>
                <br>
                <a @click="productAdd = true" href="#">+ Добавить товар</a>
              </div>
            </div>
          </div>
        </div>

        <!-- ОСНОВНОЕ -->
        <div class="form-block">
          <div class="form-block__info">
            Распределение и поставка<br>
            <span class="form-block__desc">
              Опишите своими словами, как и в какой упаковочный материал нужно упаковать ваши товары.
              Если товар уже на нашем фулфилменте, укажите это в текстовом поле.<br><br>

              Загрузите маркировку «честный знак» одним архивом.<br><br>

              Скачайте файл «распределение по коробам», и создайте поставку.<br><br>

              После создания поставки загрузите файлы (шк коробов, шк поставки и распределение).
            </span>
          </div>
          <div class="form-block__fields">
            <!-- Как необходимо упаковать? -->
            <label class="mb-4">
              Как необходимо упаковать? <sup style="color: red">*</sup><br>
              <textarea v-model="howPack" @blur="blur($event, 'howPack')" :class="getClassInput('howPack')"
                        name="howPack" placeholder="Зип пакет, пузырьковая пленка, курьер пакет и т.д."></textarea>
              <span class="form__error-message" v-if="v$.howPack.$error">Опишите требования к упаковке</span>
            </label>

            <!-- Склады отгрузки -->
            <label class="mb-2">
              <span class="mb-2">Склады отгрузки: <sup style="color: red">*</sup></span>
            </label>
            <label class="">
              <input v-model="isOzonSelected" type="checkbox">
              OZON
            </label>
            <input v-if="isOzonSelected" v-model="ozonStoreName" name="storeOzon" type="text" class="form__input mb-4" placeholder="Название склада отгрузки OZON">

            <label class="">
              <input v-model="isWBSelected" type="checkbox">
              Wildberries
            </label>
            <input v-if="isWBSelected" name="storeWb" v-model="wbStoreName" type="text" class="form__input" placeholder="Название склада отгрузки Wildberries">

            <div class="flex mt-3">
              <div class="mr-2">
                <label class="mt-2">
                  Дата отгрузки
                </label>
                <flat-pickr :config="dateConfigBetween" class="form__input" name="storeDate" v-model="storeDate"/>
              </div>
              <div v-if="isOzonSelected">
                <label class="mt-2">
                  Время отгрузки
                </label>
                <input v-model="storeTime" name="storeTime" type="text" class="form__input" placeholder="12:00">
              </div>
            </div>

            <!-- Честный знак -->
            <div class="mb-4 mt-4">
              <label>Честный знак</label>
              <div v-for="index in marksCount" :key="index" class="form__input form__input-file">
                <label style="height: 100%;width: calc(100% - 40px);display: inline-block;">
                  <input @change="changeFileValue" name="realMark[]" class="hidden" type="file">
                  <span class="label">.zip или .pdf</span>
                </label>
                <span v-if="(index+1) === realMarks && realMarks > 1" class="remove" @click="realMarks--">x</span>
                <span v-else class="form__input-icon">
                  <svg class="pc-icon">
                    <use xlink:href="#custom-document-upload"></use>
                  </svg>
                </span>
              </div>
              <span @click="realMarks++" class="more-store">Ещё один +</span>
            </div>
            <!-- Распределение по коробам -->
            <div class="mb-4">
              <label>Распределение по коробам (<a href="https://lk.lite-box.ru/upload/lk/box_template.xlsx">скачать шаблон</a>)</label>
              <div v-for="index in boxesCount" :key="index" class="form__input form__input-file">
                <label style="height: 100%;width: calc(100% - 40px);display: inline-block;">
                  <input @change="changeFileValue" name="boxes[]" class="hidden" type="file">
                  <span class="label">.zip или .pdf</span>
                </label>
                <span v-if="(index+1) === boxes && boxes > 1" class="remove" @click="boxes--">x</span>
                <span v-else class="form__input-icon">
                  <svg class="pc-icon">
                    <use xlink:href="#custom-document-upload"></use>
                  </svg>
                </span>
              </div>
              <span @click="boxes++" class="more-store">Ещё один +</span>
            </div>
          </div>
        </div>

        <!-- ДОПОЛНИТЕЛЬНО -->
        <div class="form-block">
          <div class="form-block__info">
            Дополнительно<br>
            <span class="form-block__desc">
              Дополнительные услуги и пожелания
            </span>
          </div>
          <div class="form-block__fields">
            <label class="mb-2">
              <span class="mb-2">Дополнительные услуги:</span>
            </label>
            <label v-for="(item, key) in addServices" class="mb-2" :key="key">
              <input name="addService[]" :value="key" type="checkbox">
              {{ item }}
            </label>
          </div>
        </div>

        <!-- Товары -->
        <div v-if="false" class="overflow-x-auto form-block flex-col">
          <div class="mb-2"><b>Товары</b></div>
          <table class="table-prod">
            <thead>
            <tr>
              <th>Фото</th>
              <th><div class="w-60">Название <sup>*</sup></div></th>
              <th><div class="w-24">Артикул<br> продавца <sup>*</sup></div></th>
              <th><div class="w-24">Баркод МП <sup>*</sup></div></th>
              <th><div class="w-24">Бренд</div></th>
              <th><div class="w-24">Страна</div></th>
              <th><div class="w-24">Цвет</div></th>
              <th><div class="w-24">Размер<br> (L, XL, ...)</div></th>
              <th><div class="w-24">Состав</div></th>
              <th><div class="w-24">Кол-во <sup>*</sup></div></th>
              <th><div class="w-44">Склад МП <sup>*</sup></div></th>
              <th><div class="w-44">ИП поставщика <sup>*</sup></div></th>
              <th><div class="w-60">Другое</div></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in productList" :key="index">
              <td>
                <label class="table-prod__photo-bg" :ref="`labelBg${index}`">
                  <input name="prod-photo[]" type="file" class="hidden" @change="handleFileChange($event, index)">
                  <svg class="table-prod__photo-icon">
                    <use xlink:href="#photo-icon-svg"></use>
                  </svg>
                </label>
              </td>
              <td class="relative">
                <input name="prod-name[]" type="text" class="form__input" required>
                <span v-if="productList.length > 1 && (index + 1) == productList.length" @click="removeProduct(index)" style="position: absolute; top: 21px; right: 20px; cursor: pointer">x</span>
              </td>
              <td><input name="prod-art[]" type="text" class="form__input" required></td>
              <td><input name="prod-barcode[]" type="text" class="form__input" required></td>
              <td><input name="prod-brand[]" type="text" class="form__input"></td>
              <td><input name="prod-country[]" type="text" class="form__input"></td>
              <td><input name="prod-color[]" type="text" class="form__input"></td>
              <td><input name="prod-size[]" type="text" class="form__input"></td>
              <td><input name="prod-sostav[]" type="text" class="form__input"></td>
              <td><input name="prod-amount[]" type="text" class="form__input" required></td>
              <td><input name="stock[]" type="text" class="form__input" required></td>
              <td><input name="prod-ip[]" type="text" class="form__input" required></td>
              <td><input name="prod-other[]" type="text" class="form__input" placeholder="Срок годности или дата производства"></td>
            </tr>
            </tbody>
          </table><br>

          <span @click="addProduct" class="more-store">Ещё один +</span>
          <br>
        </div>

        <!-- -->
        <div class="button-add">
          <button type="submit" class="base-button">Отправить</button>
        </div>

      </form>
    </div>
    <svg class="hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
      <symbol id="custom-document-upload" viewBox="0 0 24 24">
        <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="currentcolor"></path>
        <path d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z" fill="currentcolor"></path>
      </symbol>
      <symbol id="custom-video-play" viewBox="0 0 24 24">
        <path d="M14.7295 2H9.26953V6.36H14.7295V2Z" fill="currentcolor"></path>
        <path d="M16.2305 2V6.36H21.8705C21.3605 3.61 19.3305 2.01 16.2305 2Z" fill="currentcolor"></path>
        <path d="M2 7.85938V16.1894C2 19.8294 4.17 21.9994 7.81 21.9994H16.19C19.83 21.9994 22 19.8294 22 16.1894V7.85938H2ZM14.44 16.1794L12.36 17.3794C11.92 17.6294 11.49 17.7594 11.09 17.7594C10.79 17.7594 10.52 17.6894 10.27 17.5494C9.69 17.2194 9.37 16.5394 9.37 15.6594V13.2594C9.37 12.3794 9.69 11.6994 10.27 11.3694C10.85 11.0294 11.59 11.0894 12.36 11.5394L14.44 12.7394C15.21 13.1794 15.63 13.7994 15.63 14.4694C15.63 15.1394 15.2 15.7294 14.44 16.1794Z" fill="currentcolor"></path>
        <path d="M7.76891 2C4.66891 2.01 2.63891 3.61 2.12891 6.36H7.76891V2Z" fill="currentcolor"></path>
      </symbol>
      <symbol id="photo-icon-svg" viewBox="0 0 471.04 471.04">
        <path d="M414.72 112.64h-49.152l-27.136-40.96c-10.24-15.36-28.16-24.576-46.592-24.576H179.2c-18.432 0-36.352 9.216-46.592 24.576l-27.136 40.96H56.32A56.158 56.158 0 0 0 0 168.96v198.656a56.158 56.158 0 0 0 56.32 56.32h358.4a56.158 56.158 0 0 0 56.32-56.32V168.96a56.158 56.158 0 0 0-56.32-56.32zm-179.2 265.216c-70.144 0-126.976-56.832-126.976-126.976s56.832-126.464 126.976-126.464 126.976 56.832 126.976 126.976c0 69.632-56.832 126.464-126.976 126.464zM407.552 192h-22.528c-9.216-.512-16.384-8.192-15.872-17.408.512-8.704 7.168-15.36 15.872-15.872h20.48c9.216-.512 16.896 6.656 17.408 15.872.512 9.216-6.144 16.896-15.36 17.408z" fill="#a1baba" opacity="1" data-original="#000000" class=""/>
        <path d="M235.52 180.736c-38.912 0-70.656 31.744-70.656 70.656s31.744 70.144 70.656 70.144 70.656-31.744 70.656-70.656c0-38.912-31.744-70.144-70.656-70.144z" fill="#a1baba" opacity="1" data-original="#000000" class=""/>
      </symbol>
    </svg>

    <div :class="{'modal': true, 'is-active': productAdd === true || productEdit !== false}">
      <div class="modal__wrapper">
        <div class="modal__overlay" data-close-modal></div>
        <form @submit.prevent="productAddSubmit" ref="editForm" class="modal__content">

          <h1 class="mt-0 mb-4">
            <template v-if="productEdit !== false">Изменить товар</template>
            <template v-else>Добавить товар</template>
          </h1>

          <label><input name="prod-photo" type="file" class="form__input file" accept="image/*"></label>
          <label><input name="prod-name" :value="productCurrentEdit['prod-name']" type="text" class="form__input" placeholder="Название товара *" required></label>
          <label><input name="prod-art" :value="productCurrentEdit['prod-art']" type="text" class="form__input" placeholder="Артикул продавца *" required></label>
          <label><input name="prod-barcode" :value="productCurrentEdit['prod-barcode']" type="text" class="form__input" placeholder="Баркод МП *" required></label>
          <label><input name="prod-brand" :value="productCurrentEdit['prod-brand']" type="text" class="form__input" placeholder="Бренд"></label>
          <label><input name="prod-country" :value="productCurrentEdit['prod-country']" type="text" class="form__input" placeholder="Страна"></label>
          <label><input name="prod-color" :value="productCurrentEdit['prod-color']" type="text" class="form__input" placeholder="Цвет"></label>
          <label><input name="prod-size" :value="productCurrentEdit['prod-size']" type="text" class="form__input" placeholder="Размер (L, XL, ...)"></label>
          <label><input name="prod-sostav" :value="productCurrentEdit['prod-sostav']" type="text" class="form__input" placeholder="Состав"></label>
          <label><input name="prod-amount" :value="productCurrentEdit['prod-amount']" type="number" class="form__input" placeholder="Кол-во *" required></label>
          <label><input name="stock" :value="productCurrentEdit['stock']" type="text" class="form__input" placeholder="Склад МП *" required></label>
          <label><input name="prod-ip" :value="productCurrentEdit['prod-ip']" type="text" class="form__input" placeholder="ИП поставщика *" required></label>
          <label><input name="prod-other" :value="productCurrentEdit['prod-other']" type="text" class="form__input" placeholder="Срок годности или дата производства"></label>

          <div class="mt-4">
            <button class="order-add-btn base-button m-0 mr-2">
              <template v-if="productEdit !== false">Сохранить</template>
              <template v-else>Добавить</template>
            </button>
            <a v-if="productEdit !== false" href="#">Удалить</a>
            <a v-else href="#">Отменить</a>
          </div>

          <button @click="productEdit = false; productAdd = false" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап" data-close-modal>
            <svg width="15px" height="15px" viewBox="0 0 30 30" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <path d="M30 0L30 0L30 30L0 30L0 0L30 0Z" id="path_1" />
                <clipPath id="clip_1">
                  <use xlink:href="#path_1" clip-rule="evenodd" fill-rule="evenodd" />
                </clipPath>
              </defs>
              <g id="icon-close">
                <path d="M30 0L30 0L30 30L0 30L0 0L30 0Z" id="icon-close" fill="none" stroke="none" />
                <path d="M7 4C6.74413 4 6.48797 4.09747 6.29297 4.29297L4.29297 6.29297C3.90197 6.68397 3.90197 7.31703 4.29297 7.70703L11.5859 15L4.29297 22.293C3.90197 22.684 3.90197 23.317 4.29297 23.707L6.29297 25.707C6.68397 26.098 7.31703 26.098 7.70703 25.707L15 18.4141L22.293 25.707C22.683 26.098 23.317 26.098 23.707 25.707L25.707 23.707C26.098 23.316 26.098 22.683 25.707 22.293L18.4141 15L25.707 7.70703C26.098 7.31703 26.098 6.68297 25.707 6.29297L23.707 4.29297C23.316 3.90197 22.683 3.90197 22.293 4.29297L15 11.5859L7.70703 4.29297C7.51153 4.09747 7.25588 4 7 4L7 4Z" id="Shape" fill="#ffffff" stroke="none" clip-path="url(#clip_1)" />
                <path d="M7 4C6.74413 4 6.48797 4.09747 6.29297 4.29297L4.29297 6.29297C3.90197 6.68397 3.90197 7.31703 4.29297 7.70703L11.5859 15L4.29297 22.293C3.90197 22.684 3.90197 23.317 4.29297 23.707L6.29297 25.707C6.68397 26.098 7.31703 26.098 7.70703 25.707L15 18.4141L22.293 25.707C22.683 26.098 23.317 26.098 23.707 25.707L25.707 23.707C26.098 23.316 26.098 22.683 25.707 22.293L18.4141 15L25.707 7.70703C26.098 7.31703 26.098 6.68297 25.707 6.29297L23.707 4.29297C23.316 3.90197 22.683 3.90197 22.293 4.29297L15 11.5859L7.70703 4.29297C7.51153 4.09747 7.25588 4 7 4L7 4Z" id="Shape" fill="#ffffff" stroke="none" clip-path="url(#clip_1)" />
              </g>
            </svg>
          </button>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { mapState } from 'vuex'
import authHelper from '@/classes/formHelper'

import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'

export default {
  name: 'OrderNew',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  components: {
    flatPickr
  },
  data () {
    return {
      loading: false,
      url: '/ordersAdd',
      howPack: '',
      realMarks: 1,
      boxes: 1,
      productTemplate: {'prod-photo': '', 'prod-other': '', 'prod-ip': '', 'stock': '', 'prod-amount': '', 'prod-sostav': '', 'prod-size': '', 'prod-color': '', 'prod-country': '', 'prod-name': '', 'prod-art': '', 'prod-barcode': '', 'prod-brand': '' },
      productList: [],
      productEdit: false,
      productAdd: false,
      qwertyOk: '',

      isOzonSelected: false,
      isWBSelected: false,
      ozonStoreName: '',
      wbStoreName: '',
      storeDate: '',
      storeTime: '',

      dateConfigBetween: {
        wrap: true,
        altFormat: 'd.m.Y',
        allowInput: true,
        altInput: true,
        dateFormat: 'Y-m-d',
        'locale': Russian,
        altInputClass: 'form__input',
      },
    }
  },
  validations: {
    howPack: { required },
    productList: {
      $each: {
        name: { required },
      },
    },
  },
  computed: {
    ...mapState(['ordersStore']),
    productCurrentEdit() {
      if (this.productEdit !== false && this.productList.hasOwnProperty(this.productEdit)) {
        return this.productList[this.productEdit]
      }
      return this.productTemplate
    },
    addServices() {
      return this.ordersStore.addServices
    },
    marksCount() {
      return Array.from({ length: this.realMarks }, (v, k) => k);
    },
    boxesCount() {
      return Array.from({ length: this.boxes }, (v, k) => k);
    },
  },
  methods: {
    duplicateProduct(index) {
      const newItem = { ...this.productList[index] };
      this.productList.splice(index + 1, 0, newItem);
    },
    getProductTitle(key) {
      let product = this.productList[key],
          title = product['prod-name']

      if (product['prod-size']) {
        title += ', ' + product['prod-size']
      }

      if (product['prod-color']) {
        title += ', ' + product['prod-color']
      }

      return title
    },
    getProductPic(key) {
      let a = require('@/assets/img/gallery.png')
      let ob = {
        backgroundImage: `url(${a})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: .4
      }

      if (!('prod-photo' in this.productList[key])) {
        return ob
      }
      if (!('files' in this.productList[key]['prod-photo'])) {
        return ob
      }
      if (!('0' in this.productList[key]['prod-photo'].files)) {
        return ob
      }

      let file = this.productList[key]['prod-photo'].files[0];

      // Проверяем, что файл является изображением
      if (file.type.startsWith('image/')) {
        // Создаем URL для файла
        let imageUrl = URL.createObjectURL(file);

        return {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      }

      return {}
    },
    productAddSubmit() {
      if (this.productEdit !== false) {
        this.productSaveMethod()
      } else {
        this.productAddMethod()
      }
    },
    productSaveMethod() {
      let form = this.$refs['editForm']
      const formData = new FormData(form);
      const dataObject = this.productList[this.productEdit];

      formData.forEach((value, key) => {
        if (key === 'prod-photo') {
          let sourceFile = form.querySelector('.file')
          const files = sourceFile.files;
          if (files.length > 0) {
            const file = files[0];
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            dataObject[key] = dataTransfer
          } else {
            dataObject[key] = {}
          }
        } else {
          dataObject[key] = value;
        }
      })

      this.productList[this.productEdit] = dataObject
      this.productEdit = false
      this.productAdd = false
    },
    productAddMethod() {
      let form = this.$refs['editForm']
      const formData = new FormData(form);
      const dataObject = {};

      formData.forEach((value, key) => {
        if (key === 'prod-photo') {
          let sourceFile = form.querySelector('.file')
          const files = sourceFile.files;
          if (files.length > 0) {
            const file = files[0];
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            dataObject[key] = dataTransfer;
          } else {
            dataObject[key] = {}
          }
          sourceFile.value = ''
        } else {
          dataObject[key] = value;
        }
      })

      this.productList.push(dataObject)
      this.productEdit = false
      this.productAdd = false

    },
    productDeleteMethod(key) {
      if (this.productList.hasOwnProperty(key)) {
        if (key >= 0 && key < this.productList.length) {
          this.productList.splice(key, 1);
        }
      }
    },
    addProduct() {
      this.productList.push(this.productTemplate);
    },
    removeProduct(index) {
      this.productList.splice(index, 1);
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const label = this.$refs[`labelBg${index}`][0];
          if (label) {
            label.style.backgroundImage = `url(${e.target.result})`;
            label.style.backgroundSize = 'cover';
            label.style.backgroundPosition = 'center';
            label.querySelector('svg').style.display = 'none'
          }
        };
        reader.readAsDataURL(file);
      }
    },
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
    copyValueFiles() {
      // Метод копирует значение файлов из массива объектов товаров в физический input type=file
      this.productList.forEach((item, key) => {
        let dataTransfer = item['prod-photo']
        if (dataTransfer && 'files' in dataTransfer) {
          this.$refs['file' + key][0].files = dataTransfer.files
        }
      })
    },
    async preSubmit($event, call) {
      this.copyValueFiles()
      this.$store.commit('setLoading', true)
      await this.submit($event, call)
      this.$store.commit('setLoading', false)
    },
    redirectToDetail(data) {
      window.location.href = '/orders/' + data.id
    },
    changeFileValue(event) {
      let label = event.target.nextElementSibling
      label.innerText = event.target.files[0].name
      label.style.opacity = 1
    },
  },
}
</script>

<style lang="scss">
.button-add {
  width: 760px;
  display: flex;
  justify-content: flex-end
}

@media (max-width: 820px) {
  .button-add {
    width: auto;
  }

  h1 i,
  h1 .h1__current {
    display: none;
  }
}

.more-store {
  border-bottom: 1px dashed;
  display: inline;
  margin: -14px 0 10px 0;
  align-self: flex-start;
  font-size: 13px;
  cursor: pointer;
  color: #006666;

  &:hover {
    color: #005252;
  }
}

.remove {
  position: absolute;
  right: 6px;
  top: 13px;
  width: 16px;
  height: 17px;
  background: #a1baba;
  text-align: center;
  border-radius: 5px;
  line-height: 15px;
  font-size: 13px;
  color: white;
  cursor: pointer;
}
</style>
